import React from 'react';

import Img from 'gatsby-image';


const FeaturedHero = props => {
  
  return (
    <section id="featured-hero">
      <Img 
        fluid={props.cover}
        objectPosition="top center"
        alt={props.alt}
        className="img-fluid" />
    </section>
  )
}

export default FeaturedHero
