import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import config from '../utils/siteConfig';

import SEO from '../components/global/SEO';
import Layout from '../components/global/Layout';
import Container from '../components/global/Container';
import FeaturedHeader from '../components/featured/Header';
import FeaturedHero from '../components/featured/Hero';
import FeaturedContent from '../components/featured/Content';

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      frontmatter {
        order
        title
        slug
        category
        tags
        description
        keywords
        intro
        share
        agency
        role
        tech
        cover {
          childImageSharp {
            fluid(quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
        imageAlt
      }
      html
      fields {
        slug
      }
    }
  }
`;


const ChallengeDetailTemplate = (props) => {

  const featured = props.data.markdownRemark;
  const pagePath = props.location.pathname;

  const socialData = {
    title: `${featured.frontmatter.title}`,
    description: `${featured.frontmatter.description}`,
    keywords: `${featured.frontmatter.keywords}`,
    share: `${featured.frontmatter.share}`,
    shareImage: `/share/${featured.frontmatter.slug}-f.jpg`,
    shareImageVert: `/share/${featured.frontmatter.slug}-p.jpg`,
  }

  const schemaData = {
    category: `${featured.frontmatter.category}`,
  }
  

  return (
    <div>
    <Layout root={pagePath}>
      <Helmet>
        <title>{`${featured.frontmatter.title} | ${config.siteTitle}`}</title>
        <meta name="description" content={featured.frontmatter.description} />
      </Helmet>
      <SEO socialData={socialData} schemaData={schemaData} pagePath={pagePath} featuredSEO />

      <Container>
        <FeaturedHeader 
          title={featured.frontmatter.title}
          intro={featured.frontmatter.intro}
          agency={featured.frontmatter.agency}
          role={featured.frontmatter.role}
          tech={featured.frontmatter.tech}
        />
        <FeaturedHero
          cover={featured.frontmatter.cover.childImageSharp.fluid}
          alt={featured.frontmatter.recipeAlt}
        />
        <FeaturedContent
          body={featured.html}
          socialData={socialData} 
          pagePath={pagePath}
          category={featured.frontmatter.category}
        />
      </Container>
    </Layout>
    </div>
  )
}

export default ChallengeDetailTemplate
