import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const FeaturedContent = props => {

  return (
    <section id="featured-content">
      <Container>
        <Row>
          <Col xs={12} sm={10}>
            <div dangerouslySetInnerHTML={{ __html: props.body}}></div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default FeaturedContent
